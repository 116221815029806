.cards {
	padding: 8.4rem 0;

	&__intro {
		text-align: center;
		margin-bottom: 2.68rem;

		h2 {
			margin-bottom: 1.25rem;
		}
	}

	&__columns {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		margin-right: - $gutter-four;

		&--center {
			.cards__card-content {
				text-align: center;
			}

			.cards__icon {
				margin-left: auto;
				margin-right: auto;
			}
		}

		&--four {
			.cards__item {
				width: calc(25% - #{$gutter-four});
			}
		}
	}

	&__item {
		text-align: left;
		background-color: $color-four;
		width: calc(33.33% - #{$gutter-four});
		margin: 0 $gutter-four $gutter-four 0;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		justify-content: space-between;

		&--link {
			transition: box-shadow $transition-two;

			&:hover {
				box-shadow: 0 0 20px 4px rgba(0, 255, 239, 0.6);
			}
		}

		&--vertical-align {
			display: flex;
			flex-direction: column;
			justify-content: center;

			h4 {
				margin: 1.06rem 0 1.06rem;
			}
		}

		&--package {
			border-top: 5px solid $color-five;

			.cards__heading {
				margin: 0 0 1.6rem;
				color: $color-five;
			}

			.cards__sub-heading {
				font-size: 16px;
				margin-bottom: 2rem;
				margin-top: -0.4rem;
			}

			.cards__copy {
				ul {
					padding-left: 1.5rem;
					list-style: none;
				}

				li {
					&::before {
						content: "";
						position: absolute;
						left: -2.8ex;
						top: 0.4ex;
						width: 2ex;
						height: 2ex;
						background-image: url('/img/icon-tick-dark.svg');
						background-position: 50%;
						background-repeat: no-repeat;
						background-size: 1.25ex;
					}
				}
			}
		}
	}

	&__icon {
		max-width: 3rem;
		margin-bottom: 1.37rem;
	}

	&__photo {
		border-radius: 100%;
		margin-bottom: 1.5rem;
	}

	&__social {
		display: flex;
		justify-content: center;
		margin-top: 1.5rem;

		li {
			margin: 0 0.5rem;
			width: 1.5rem;
			z-index: 2;
		}
	}


	&__link {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
		cursor: pointer;
	}

	&__heading {}

	&__sub-heading {}

	&__copy {
		ul {
			list-style: disc;
			padding-left: 1rem;
			margin-bottom: 2rem;
		}

		li {
			margin-top: 1rem;
		}
	}

	&__list {
		list-style: disc;
		padding-left: 1rem;
	}

	&__list-item {
		margin-top: 1rem;
	}

	&__card-content {
		padding: 1.43rem 1.87rem;
		width: 100%;
	}

	&__message {
		width: 100%;
		margin-top: 0.5rem;
		padding: 1.1rem 1.87rem;
		background-color: $color-one;
		color: $color-four;
	}

	&__button {
		left: 50%;
		transform: translateX(-50%);
		margin-top: 40px;
	}

	@media (max-width: 900px) {
		&__columns {
			&--four {
				.cards__item {
					width: calc(50% - #{$gutter-four});
				}
			}
		}
	}

	@media (max-width: 700px) {
		&__item {
			width: calc(50% - #{$gutter-four});
		}
	}

	@media (max-width: 630px) {
		padding: 6.4rem 0;
	}

	@media (max-width: 500px) {
		&__columns {
			&--four {
				.cards__item {
					width: calc(100% - #{$gutter-four});
				}
			}
		}

		&__item {
			width: calc(100% - #{$gutter-four});
		}
	}
}
