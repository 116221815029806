.slider {
	padding: 8.4rem 0 0;
	z-index: 2;

	+ * {
		margin-top: -2rem;
		z-index: 1;
	}

	&__container {}

	&__heading {
		width: calc(33.33% - #{$gutter-four});
		margin-bottom: 0;
	}

	&__sliders {
		display: flex;
		margin-right: - 5.87rem;
		align-items: center;

		&::before {
			content: "";
			position: absolute;
			top: 3rem;
			left: -4rem;
			bottom: 2.7vw;
			width: calc(100% + 4rem);
			background-color: $color-five;
		}
	}

	&__wrapper {
		width: calc(33.33% - #{$gutter-four});
		margin-right: $gutter-four;
		color: $color-four;

		&:last-of-type {
			width: calc(66.66% + #{$gutter-four});
		}
	}

	&__nav {
		.slick-arrow {
			display: none !important;
		}

		.slick-dots {
			text-align: left;
			bottom: -2rem;

			li {
				height: 0.8rem;
				width: 0.8rem;
				margin: 0 1rem 0 0;
				background-color: $color-four;
				border-radius: 100%;

				&.slick-active {
					background-color: $color-three;
				}
			}

			button {
				opacity: 0;
			}
		}
	}

	&__screen {
		width: 100%;
		margin-left: 5rem;
	}

	@media (max-width: 1000px) {
		background-color: $color-five;
		padding: 6.4rem 0;

		&__heading {
			width: calc(100% - #{$gutter-four});
			margin-bottom: 0;
			color: $color-four;
			margin-bottom: 3rem;
			text-align: center;
		}

		&__sliders {
			width: 100%;
			text-align: center;
			flex-wrap: wrap;
			flex-direction: column-reverse;

			&::before {
				display: none;
			}
		}

		&__wrapper {
			width: 100%;
			margin: 0;

			&:last-of-type {
				width: 100%;
			}
		}

		&__screen {
			margin-left: 0;
			margin-bottom: 1rem;
		}

		.slick-dots {
			text-align: center;
		}
	}
}
