.background {
	&--one {
		background-color: $color-one;
	}

	&--two {
		background-color: $color-two;
	}

	&--three {
		background-color: $color-three;
	}

	&--four {
		background-color: $color-four;
	}

	&--five {
		background-color: $color-five;
	}

	&--six {
		background-color: $color-six;
	}

	&--seven {
		background-color: $color-seven;
	}
}

.text {
	&--left {
		text-align: left;
	}

	&--right {
		text-align: right;
	}

	&--center {
		text-align: center;
	}
}

.scroll {
	&--none {
		overflow: hidden;
	}

	&--x {
		overflow-y: hidden;
		overflow-x: auto;
	}

	&--y {
		overflow-x: hidden;
		overflow-y: auto;
	}
}

.bold {
	font-weight: 700 !important;
}

.italic {
	font-style: italic;
}

.underline {
	text-decoration: underline;
}

.upper {
	text-transform: uppercase;
}
