.icon-list {
	&__item {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin-bottom: 0.5rem;
	}

	&__icon {
		width: 1.7rem;
		position: relative;
		top: 3px;
	}

	&__copy {
		width: calc(100% - 3.2rem);

		a {
			color: inherit;
			text-decoration: none;
			transition: $transition-one;

			&:hover {
				color: $color-three;
			}
		}
	}
}
