// SPACING
$gutter-one: 4rem;
$gutter-two: 2rem;
$gutter-three: 3.74rem;
$gutter-four: 1.87rem;

// CONTAINER WIDTHS
$container-one: 93rem;
$container-two: 70.8rem;
$container-three: 56.25rem;

// FONTS
$resp-font-size: calc(12px + 0.4vw);
$font-size-one: 16px;
$font-size-two: 14px;
$line-height: 1.4;
$font-one: 'Open Sans', sans-serif;

// COLOURS
$color-one: #4A4D50; // main text
$color-two: #828588; // background light grey
$color-three: #00FFEF; // main cta colour
$color-four: #fff; // white
$color-five: #1F414A; // panel green
$color-six: #082636; // panel blue
$color-six-t: rgba(8, 38, 54, 0.9); // transparent blue
$color-seven: #F8F8F8; // background super light grey

// TRANSITIONS
$transition-one: ease 0.4s;
$transition-two: ease 0.2s;
$transition-three: ease 1s;
