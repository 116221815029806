.copy-block {
	padding: 8.4rem 0;
	text-align: center;

	&__container {}

	&__heading,
	&__copy {
		max-width: 880px;
		margin-left: auto;
		margin-right: auto;
	}

	&__copy {
		font-weight: 100;
		font-size: 22px;
		line-height: 1.4;
	}

	&__button {
		margin-top: 3rem;
	}
}

