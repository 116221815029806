.panel {
	padding: 8.4rem 0;

	&--image {
		padding: 0;

		.panel__columns {
			align-items: unset;
		}

		.panel__item {
			&:not(.panel__item--image) {
				padding: 4rem 0;
			}
		}

		&--flip {
			.panel__columns {
				flex-direction: row-reverse;
			}

			.panel__item--image {
				margin-right: -0.18rem;
				margin-left: 0;
			}
		}
	}

	&__container {}

	&__columns {
		display: flex;
		flex-wrap: wrap;
		align-items: baseline;
		margin-right: - $gutter-three;
	}

	&__heading {
		width: calc(100% - 3.74rem);
		margin: 0 0 1.25rem;

		&--hero {
			margin-bottom: 6rem;
		}
	}

	&__item {
		width: calc(50% - #{$gutter-three});
		margin-right: $gutter-three;
		order: 2;

		&--image {
			padding-bottom: calc(50% + 0.18rem);
			margin-left: -4rem;
			width: calc(50% + 0.18rem);
			background-position: 50%;
			background-size: cover;

			&--right {
				margin-right: -0.18rem;
				margin-left: 0;
			}
		}
	}

	@media (min-width: 629px) and (max-width: 1200px) {
		&--image {
			.paragraph-one {
				font-size: 1.2rem;
			}
		}
	}

	@media (max-width: 630px) {
		padding: 6.4rem 0;

		&--image {
			padding: 0;
		}

		&__item {
			width: calc(100% - #{$gutter-three});
			margin-bottom: 2rem;

			&--image {
				margin-bottom: $gutter-three;
				margin-left: -4rem;
				margin-right: 0;
				padding-bottom: 100%;
				width: calc(100% + 4rem);

				&--right {
					order: 1;
				}
			}
		}
	}
}
