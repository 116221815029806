.contact-modal {
	opacity: 0;
	pointer-events: none;
	position: static;
	transition: ease 0.2s;

	&--active {
		opacity: 1;
		pointer-events: all;

		.contact-modal__content {
			opacity: 1;
			transform: translate(-50%, 0);
		}
		
	}

	&__content {
		position: absolute;
		top: 0;
		left: 50%;
		transform: translate(-50%, 10%);
		width: 100%;
		max-width: 570px;
		z-index: 11;
		padding: 1rem 1.5rem;
		opacity: 0;
		transition:  ease 0.4s;
	}

	&__inner {
		background-color: $color-six;
		color: $color-four;
		padding: 2rem;
	}

	&__mask {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,0.7);
		z-index: 10;
	}

	&__heading {
		font-size: 1.6rem;
		margin: 0.5rem 3rem 0.5rem 0;
	}

	&__copy {
		margin-bottom: 2rem;
		font-size: 14px;
		line-height: 1.4;
	}

	&__form {}

	.form {
		margin-top: 1.5rem;
	}

	&__close {
		position: absolute;
		top: 1rem;
		right: 1rem;
		width: 1.25rem;
		height: 1.25rem;
		cursor: pointer;
	}
}
