.logo-block {
	padding: 8.4rem 0 5rem;
	text-align: center;

	&__container {}
	&__heading {}

	&__cols {
		display: flex;
		flex-wrap: wrap;
		margin-right: - 3.75rem;
	}

	&__logo {
		width: calc(50% - 3.75rem);
		margin: 0 3.75rem 3.75rem 0;

		@media (min-width: 650px) {
			width: calc(25% - 3.75rem);
		}
	}

	&__img {}
}
