.button {
	display: inline-block;
	text-decoration: none;
	font-size: 1rem;
	font-weight: 700;
	padding: 0.85rem 1.85rem 0.9rem;
	border-radius: 0.25rem;
	transition: all $transition-two;
	cursor: pointer;
	color: $color-one;
	background-color: $color-three;
	box-shadow: 0 0 20px 4px rgba(0, 255, 239, 0);
	text-align: center;

	&--large {
		font-size: 1.37rem;
		padding: 1.4rem 2.9rem 1.5rem;
	}

	&--secondary {
		color: $color-three !important;
		background-color: transparent;
		border: 2px solid $color-three;
	}

	&:hover {
		box-shadow: 0 0 20px 4px rgba(0, 255, 239, 0.6);
	}
}
