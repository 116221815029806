.tick-list {
	display: flex;
	flex-wrap: wrap;
	margin-right: - $gutter-four;

	&__item {
		width: calc(100% - #{$gutter-four});
		margin: 0 $gutter-four $gutter-four 0;
		padding-left: 1.3rem;

		&::before {
			position: absolute;
			top: 0.4rem;
			left: 0;
			content: '';
			width: 0.6rem;
			height: 0.6rem;
			background-image: url('/img/ellipse.png');
			background-position: 50%;
			background-size: contain;
			background-repeat: no-repeat;
		}
	}
}
