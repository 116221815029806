.header {
	padding: 3.75rem 0 2rem;
	margin-bottom: -9.4rem;
	z-index: 10;
	position: fixed;
	top: 0;
	width: 100vw;
	transition: $transition-three;

	&--dark {
		background-color: $color-six-t;
		padding-top: 1.5rem;

		.header__logo {
			max-width: 8rem;
		}
	}

	+ main {
		> * {
			&:first-of-type {
				.container {
					padding-top: 9.4rem;
				}
			}
		}
	}

	&__container {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&__logo {
		max-width: 10rem;
	}

	&__nav-item-wrapper {
		display: inline-block;
		margin-left: 1.25rem;

		&:first-of-type {
			margin-left: 0;
		}

		&:last-of-type {
			margin-left: 1.87rem;
		}

		&:hover {
			> .header__nav-dropdown {
				opacity: 1;
				pointer-events: all;
			}
		}
	}

	&__nav-item {
		color: $color-four;
		text-decoration: none;
		transition: color 0.2s;

		&--button {
			color: $color-one;
		}

		&--mob-hide {
			@media (max-width: 1200px) {
				display: none;
			}
		}

		&:hover {
			color: $color-three;
		}
	}

	&__nav-dropdown {
		
		@media (min-width: 1200px) {
			opacity: 0;
			pointer-events: none;
			position: absolute;
			left: -1rem;
			padding-top: 1rem;
			width: 200px;
			font-size: 14px;
		}

		.header__nav-item {
			display: block;
			margin: 1.5rem 0 0 !important;

			@media (min-width: 1200px) {
				margin: 0 0 1rem !important;
			}
		}
	}


	&__nav-dropdown-content {
		@media (min-width: 1200px) {
			padding: 1.5rem 1rem 1rem;
			background: $color-five;
		}
	}

	&__nav-trigger {
		display: none;
		width: 1.5rem;
		transition: $transition-one;
		cursor: pointer;

		&--close {
			position: absolute;
			right: 4.1rem;
			top: 4.8rem;
			width: 1.4rem;
		}

		&.hidden {
			opacity: 0;
		}
	}

	@media (max-width: 1200px) {
		&__nav-item-wrapper {
			margin-left: 1rem;
		}

		&__nav-item {
			&.button {
				padding: 0.85rem 0.9rem 0.9rem;
			}

			&:last-of-type {
				margin-left: 1rem;
			}
		}
	}

	@media (max-width: 1120px) {
		&__nav-trigger {
			display: block;
		}

		&__nav {
			width: 320px;
			position: fixed;
			top: 0;
			left: 100%;
			padding: 9rem $gutter-one $gutter-one;
			text-align: right;
			height: 100vh;
			background-color: $color-six;
		}

		&__nav-item-wrapper {
			margin: 0 0 1.5rem;
			display: block;
		}

		&__nav-item {

			&:first-of-type,
			&:last-of-type {
				margin: 0 0 1.5rem;
			}

			&.button {
				margin-top: 3rem;
			}
		}
	}
}

body {
	transition: $transition-one;

	&.nav-slide {
		transform: translateX(-320px);
		transition: ease 0.4s;
	}
}
