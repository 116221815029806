// @font-face {
// 	font-family: 'Champion';
// 	src: url('/fonts/Champion-Bantamweight.eot');
// 	src: url('/fonts/Champion-Bantamweight.eot?#iefix') format('embedded-opentype'),
// 		url('/fonts/Champion-Bantamweight.woff2') format('woff2'),
// 		url('/fonts/Champion-Bantamweight.woff') format('woff'),
// 		url('/fonts/Champion-Bantamweight.ttf') format('truetype'),
// 		url('/fonts/Champion-Bantamweight.svg') format('svg');
// 	font-weight: 400;
// 	font-style: normal;
// }

* {
	p:last-of-type {
		margin-bottom: 0;
	}
}

$font-breakpoint-one: 740px;

.heading-one {
	font-size: 3.12rem;
	margin: 0 0 2.6rem;
	font-weight: 700;

	@media (max-width: $font-breakpoint-one) {
		font-size: 2.77rem;
	}
}

.heading-two {
	font-size: 2.37rem;
	margin: 0 0 2.6rem;
	font-weight: 700;

	@media (max-width: $font-breakpoint-one) {
		font-size: 2.2rem;
	}
}

.heading-three {
	font-size: 1.75rem;
	margin: 0 0 1.06rem;
	font-weight: 700;

	@media (max-width: $font-breakpoint-one) {
		font-size: 1.4rem;
	}
}

.heading-four {
	font-size: 1.37rem;
	margin: 0 0 1.06rem;
	font-weight: 700;

	@media (max-width: $font-breakpoint-one) {
		font-size: 1.2rem;
	}
}

.paragraph-one {
	font-family: $font-one;
	line-height: 1.4;
	font-size: 1.3rem;
	margin: 0 0 1rem;
	font-weight: 300;

	p {
		margin: 0 0 1rem;
	}

	@media (max-width: $font-breakpoint-one) {
		font-size: 1rem;
	}
}

.paragraph-two {
	font-family: $font-one;
	line-height: 1.4;
	font-size: 1rem;
	margin: 0 0 1rem;
	font-weight: 400;
}

.paragraph-three {
	font-family: $font-one;
	line-height: 1.4;
	font-size: 1.12rem;
	margin: 0 0 1rem;
	font-weight: 300;

	@media (max-width: $font-breakpoint-one) {
		font-size: 1rem;
	}
}

* {
	strong {
		font-weight: 600;
	}

	em {
		font-style: italic;
	}
}
