*,
*::before,
*::after {
	box-sizing: border-box;
	position: relative;
}

html {
	color: $color-one;
	overflow-x: hidden;
	padding: 0;
	scroll-behavior: smooth;
	// font-size: $resp-font-size;
	font-size: $font-size-one;
	font-family: $font-one;
	line-height: $line-height;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-feature-settings: 'tnum';
	font-variant-numeric: tabular-nums;

	// @media (max-width: 1080px) {
	// 	font-size: $font-size-one;
	// }

	@media (max-width: 850px) {
		font-size: $font-size-two;
	}
}

ul,
ol {
	padding: 0;
	list-style: none;
}

body,
h1,
h2,
h3,
h4,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
	color: inherit;
	margin: 0;
}

body {
	min-height: 100vh;
	text-rendering: optimizeSpeed;
}

main {
	overflow: hidden;
}

a:not([class]) {
	text-decoration-skip-ink: auto;
}

img,
svg,
video,
hr {
	width: 100%;
	display: block;
}

input,
button,
textarea,
select {
	font: inherit;
}

table,
th,
td {
	border: 1px solid currentColor;
	text-align: left;
}

td,
th {
	padding: 1rem;
	vertical-align: top;
}

table {
	border-collapse: collapse;
	margin: 2rem 0;
}

th {
	font-weight: 800;
}
