.hero {
	background-position: 50%;
	background-size: cover;

	&__container {
		display: flex;
		z-index: 2;
	}

	&__columns {
		display: flex;
		align-items: center;
		margin-right: - $gutter-three;
		padding: 4rem 0;
	}

	&__content {
		color: $color-four;
		width: calc(70% - #{$gutter-three});
		margin-right: $gutter-three;
	}

	&__button {
		margin-top: 3.25rem;
	}

	&__arrow {
		max-width: 1.56rem;
		display: block;
		margin-top: 3.25rem;
	}

	&__background-video {
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		overflow: hidden;

		video {
			position: absolute;
			min-height: 100%;
			min-width: 100%;
			width: auto;
		}
	}

	@media (max-width: 700px) {
		&__content {
			width: calc(100% - #{$gutter-three});
		}
	}
}
