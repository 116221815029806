.texture {
	overflow: hidden;

	&::before {
		content: '';
		width: 110vw;
		height: 110vw;
		background-image: url('/img/bg-logo-white.png');
		background-size: cover;
		background-position: 50%;
		background-repeat: no-repeat;
		display: block;
		position: absolute;
		opacity: 0.05;
		transform: translateY(-50%);
	}

	&--dark {
		&::before {
			background-image: url('/img/bg-logo-grey.png');
		}
	}

	&--right {
		&::before {
			top: 35%;
			right: -80%;
		}
	}

	&--left {
		&::before {
			left: -46%;
			top: -2%;
		}
	}

	&--right-mid {
		&::before {
			top: 70%;
			right: -55%;
		}
	}
}
