.contact {
	padding: 8.4rem 0;
	color: $color-four;

	&__container {
		position: static;
	}

	&__columns {
		display: flex;
		flex-wrap: wrap;
		margin-right: - $gutter-three;
	}

	&__item {
		width: calc;
		width: calc(50% - #{$gutter-three});
		margin-right: $gutter-three;
	}

	&__badge {
		max-width: 9rem;
		transform: rotate(-90deg);
		position: absolute;
		left: -25px;
		bottom: 50px;

		p {
			font-size: 11px;
			margin-bottom: 8px;
		}

		img {
			max-width: 85px;
		}
	}

	&__social {
		margin: 2.8rem 0 1rem;
	}

	&__social-item {
		display: inline-block;
		max-width: 2.5rem;
		margin-right: 1rem;
	}

	@media (max-width: 630px) {
		padding: 6.4rem 0 2rem;

		&__item {
			width: calc(100% - #{$gutter-three});

			&:first-of-type {
				margin-bottom: 2rem;
			}
		}

		&__badge {
			margin-top: 6rem;
			position: relative;
			left: unset;
			bottom: unset;
			transform: rotate(0);
		}
	}
}

