.form {
	margin-top: 2rem;

	&__sub-heading {
		width: 100%;
		font-weight: 600;
		margin-bottom: 1rem;
		font-size: 0.87rem;
	}

	.errors {
		font-size: 0.87rem;
		margin-top: 0.3rem;
	}

	&__columns {
		display: flex;
		flex-wrap: wrap;
		margin: 0 0 1.25rem;

		.form__item {
			width: 50%;
			margin: 0 -1px -1px 0;
		}
	}

	&__item {
		width: 100%;
		margin: 0 0 2rem;
		border: 1px solid $color-three;

		&--chackbox {
			border: 0;
			padding: 0 1rem;
		}
	}

	&__label {
		position: absolute;
		top: 0;
		left: 0;
		font-size: 0;

		&--checkbox {
			font-size: 0.85rem;
			position: relative;
			display: flex;
			align-items: center;
			margin-bottom: 0.6rem;

			input {
				width: 1rem !important;
				margin-right: 0.5rem;
			}
		}
	}


	&__captcha-notice {
		width: 50%;
		padding-left: 2rem;
		font-size: 0.8rem;
		line-height: 1.4;
		color: #fff;

		a {
			color: $color-three;
			text-decoration: underline;

			&:hover {
				color: #fff;
			}
		}
	}

	input,
	textarea {
		width: 100%;
		border: 0;
		font-size: 1rem;
		font-weight: 100;

		&:not([type="submit"]) {
			background: transparent;
			color: #fff;
			padding: 0.8rem 1rem;
		}
	}

	input[type="submit"] {
		font-weight: 600;
		min-width: 50%;
		width: auto;
	}

	@media (max-width: 900px) {
		input,
		textarea {
			&:not([type="submit"]) {
				padding: 0.4rem 0.6rem;
			}
		}
	}
}
