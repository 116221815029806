.scroll-anchor {
	position: absolute;
	width: 100%;
	height: 1px;
	pointer-events: none;
	top: 0;

	&--bottom {
		top: unset;
		bottom: 6rem;
	}
}
