.container {
	max-width: $container-one;
	// padding-top: 1px;
	// padding-bottom: 1px;
	padding-left: $gutter-one;
	padding-right: $gutter-one;
	margin: auto;

	&--fill {
		min-height: 100vh;
	}

	@media (max-width: 550px) {
		padding-left: $gutter-two;
		padding-right: $gutter-two;
	}
}
